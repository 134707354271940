<template>
  <div class="home_page">
    <div class="compare">
      <!-- <div class="y-header-title m-t-30 m-b-30">
        <span style="font-size: 20px;font-weight: 500">
          <span>院校对比</span>
        </span>
      </div> -->
      <div class="table-box">
        <table class="compare-table" style="width: 100%">
          <thead>
            <tr>
              <th class="info">院校信息</th>
              <th class="info-item" v-for="(item, index) in list" :key="index">{{ item.name }}
                <!--<div style="cursor: pointer">
                  <div class="m-b-20">{{ item.name }}</div>
                   <div
                    class="el-image"
                    style="width: 72px; height: 72px; cursor: pointer;"
                  >
                    <img
                      class="el-image__inner"
                      :src="item.logo"
                      style="object-fit: contain;"
                    />
                  </div>
                </div> -->
              </th>
            </tr>
          </thead>
          <tbody class="yesdata">
            <tr>
              <td>综合排名</td>
              <td v-for="item in list">{{ item.rank }}</td>
            </tr>
            <tr>
              <td>所属地区</td>
              <td v-for="item in list">
                {{ filterProvince(item.provinceCode) }} {{ item.cityName }}
              </td>
            </tr>
            <tr>
              <td>创办时间</td>
              <td v-for="item in list">{{ item.createdYear }}</td>
            </tr>
            <tr>
              <td>办学性质</td>
              <td v-for="item in list">{{ item.natureType }}</td>
            </tr>
            <tr>
              <td>隶属于</td>
              <td v-for="item in list">{{ item.belong }}</td>
            </tr>
            <tr>
              <td>学科层次</td>
              <td v-for="item in list">{{ item.eduLevel }}</td>
            </tr>
            <tr>
              <td>院校类型</td>
              <td v-for="item in list">
                {{ item.categories.length > 0 ? item.categories[0] : '-' }}
              </td>
            </tr>
            <tr>
              <td>985</td>
              <td v-for="item in list">
                {{ item.features.toString().indexOf('985') > -1 ? '√' : '-' }}
              </td>
            </tr>
            <tr>
              <td>211</td>
              <td v-for="item in list">
                {{ item.features.toString().indexOf('211') > -1 ? '√' : '-' }}
              </td>
            </tr>
            <tr>
              <td>双一流</td>
              <td v-for="item in list">
                {{
                  item.features.toString().indexOf('双一流') > -1 ? '√' : '-'
                }}
              </td>
            </tr>
            <tr>
              <td>院系数</td>
              <td v-for="item in list">{{ item.departmentNum }}</td>
            </tr>
            <tr>
              <td>专业数</td>
              <td v-for="item in list">{{ item.majorNum }}</td>
            </tr>
            <tr>
              <td>博士点</td>
              <td v-for="item in list">{{ item.pointsOfBoNum }}</td>
            </tr>
            <tr>
              <td>硕士点</td>
              <td v-for="item in list">{{ item.pointsOfShuoNum }}</td>
            </tr>
            <tr>
              <td>男生占比</td>
              <td v-for="item in list">{{ item.maleRateOfStu }}</td>
            </tr>
            <tr>
              <td>女生占比</td>
              <td v-for="item in list">{{ item.femaleRateOfStu }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      province: [],
    };
  },
  async created() {
    const { path, query } = this.$router.currentRoute;
    const res = await this.$axios.get('/php/province.list');
    this.province = res.data.data;
    const list = await this.$axios.get(
      '/php/college.compare?colleges=' + query.id
    );
    this.list = list.data.data.map((e) => {
      e.pointsOfShuoNum = _.sum(e.pointsOfShuo.map((a) => a.number));
      e.pointsOfBoNum = _.sum(e.pointsOfBo.map((a) => a.number));
      return e;
    });
  },
  methods: {
    filterProvince(id) {
      const arr = this.province.filter((e) => e.id == id);
      return arr.length > 0 ? arr[0].name : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.compare {
  background: #fff;
  .y-header-title {
    border-left: 3px solid #910000;
    padding-left: 10px;
    margin-bottom: 30px;
  }
  .table-box {
    // width: 110%;
    height: auto;
    margin-bottom: 80px;
    min-width: 100vw;
    .compare-table {
      font-size: 14px !important;
      border-collapse: collapse !important;
      border-spacing: 0;
      text-align: center;
      thead {
        tr {
          th {
            background-color: #fff;
            padding: 20px 5px;
            font-size: 14px !important;
            font-weight: 500;
            // height: 182px;
          }
        }
      }
      th {
        border: 1px solid #e4e4e4 !important;
        padding: 14px;
        width: 240px;
      }
      td {
        border: 1px solid #e4e4e4 !important;
        padding: 10px;
        width: 240px;
      }
      .info {
        color: #212121 !important;
      }
      .info-item {
        position: relative;
      }
    }
  }
}
</style>
